// material-ui

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// project imports
import axiosServices from 'utils/axios';
import useAuth from 'hooks/useAuth';
import AssessmentCard from './assessment-card';
import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import PageHeader from 'views/ui-elements/PageHeader/index.js';

// ==============================|| ACCOUNT SETTINGS PAGE ||============================== //

const AssessmentsListPage = () => {
    const location = useLocation();
    const { chapter } = location.state;
    const { user } = useAuth();
    const [assessments, setAssessments] = useState();

    useEffect(() => {
        const getAssessmentsForChapter = async (chapterId) => {
            try {
                const response = await axiosServices.get(`/assessments?chapterId=${chapterId}&studentId=${user.id}`);
                setAssessments(response.data);
            } catch {
                console.log('error');
            } finally {
                console.log('close loader. job done');
            }
        };
        getAssessmentsForChapter(chapter.id);
    }, [chapter, user]);

    return (
        <>
            <PageHeader title={`${chapter.name} - Assessments`} hasBackButton={true} />
            <Grid container spacing={gridSpacing}>
                {assessments?.map((assessment) => {
                    return (
                        <Grid key={assessment.id} item xs={12} md={3} sm={12}>
                            <AssessmentCard assessment={assessment} chapter={chapter} />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default AssessmentsListPage;
