import { Button, Grid, ListSubheader, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { gridSpacing } from 'store/constant';

const TopicSkillPopover = ({ topics, categories, onSelected, reset }) => {
    const [selectedElementId, setSelectedElementId] = useState('');
    const [selectedElementType, setSelectedElementType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'topic-skill-popover' : undefined;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const elementSelected = (element) => {
        setSelectedElementId(element.id);
        element.chapterId ? setSelectedElementType('topic') : setSelectedElementType('tag');
        onSelected(element);
        handlePopoverClose();
    };

    const getElementName = (id) => {
        let returnValue;
        if (selectedElementType) {
            if (selectedElementType == 'topic' && topics && topics.length) {
                topics.forEach((topic) => {
                    if (topic.id == id) {
                        returnValue = topic.name;
                    }
                });
            } else {
                if (categories && categories.length) {
                    categories.forEach((category) => {
                        category.Tags.forEach((tag) => {
                            if (tag.id == id) {
                                returnValue = tag.name;
                            }
                        });
                    });
                }
            }
        }
        return returnValue;
    };

    useEffect(() => {
        reset && setSelectedElementId('');
        reset && setSelectedElementType('');
    }, [reset]);

    useEffect(() => {
        let preSelectedValues = JSON.parse(localStorage.getItem('preSelectedValuesInPracticePage'));
        if (preSelectedValues) {
            setSelectedElementId(preSelectedValues.element.id);
            preSelectedValues.element.chapterId ? setSelectedElementType('topic') : setSelectedElementType('tag');
            onSelected(preSelectedValues.element);
        }
    }, []);

    return (
        <SubCard contentSX={{ padding: '5px !important' }}>
            <Grid container>
                <Grid item sx={{ flexGrow: 1, minWidth: 0 }}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handlePopoverOpen}
                        sx={{
                            width: '100%',
                            minWidth: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography sx={{ fontWeight: '500' }} noWrap>
                            {selectedElementId ? getElementName(selectedElementId) : 'Pick a Topic/Skill'}
                        </Typography>
                        <KeyboardArrowDown />
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item>
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <nav aria-label="topics">
                                        <List
                                            subheader={
                                                <ListSubheader
                                                    component="div"
                                                    id="topics-subheader"
                                                    color="primary"
                                                    sx={{ textDecoration: 'underline' }}
                                                >
                                                    Topics
                                                </ListSubheader>
                                            }
                                        >
                                            {topics &&
                                                topics.map((topic, index) => {
                                                    return (
                                                        <ListItem disablePadding key={index}>
                                                            <ListItemButton
                                                                onClick={() => {
                                                                    elementSelected(topic);
                                                                }}
                                                                selected={topic.name == getElementName(selectedElementId)}
                                                                sx={{ maxWidth: '250px' }}
                                                            >
                                                                <ListItemText primary={topic.name} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>
                            {categories &&
                                categories.map((category, index) => {
                                    let tags = [];
                                    if (category.Tags && category.Tags.length) {
                                        tags = category.Tags.sort((firstItem, secondItem) => {
                                            const tag1 = firstItem.id;
                                            const tag2 = secondItem.id;
                                            if (tag1 < tag2) {
                                                return -1;
                                            }
                                            if (tag1 > tag2) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                    }
                                    return (
                                        <>
                                            {category.name != 'Subject Core' && category.name != 'MCQ Format' && (
                                                <Grid item key={index}>
                                                    <List
                                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                        component="nav"
                                                        aria-labelledby={`${category.name}-subheader`}
                                                        subheader={
                                                            <ListSubheader
                                                                component="div"
                                                                id={`${category.id}-subheader`}
                                                                color="primary"
                                                                sx={{ textDecoration: 'underline' }}
                                                            >
                                                                {category.name}
                                                            </ListSubheader>
                                                        }
                                                    >
                                                        {tags &&
                                                            tags.map((tag, index) => {
                                                                return (
                                                                    <ListItem disablePadding key={index}>
                                                                        <ListItemButton
                                                                            onClick={() => {
                                                                                elementSelected(tag);
                                                                            }}
                                                                            selected={tag.name == getElementName(selectedElementId)}
                                                                            sx={{ maxWidth: '250px' }}
                                                                        >
                                                                            <ListItemText primary={tag.name} />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                    </List>
                                                </Grid>
                                            )}
                                        </>
                                    );
                                })}
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>
        </SubCard>
    );
};

TopicSkillPopover.propTypes = {
    topics: PropTypes.array,
    categories: PropTypes.array,
    onSelected: PropTypes.func
};

export default TopicSkillPopover;
