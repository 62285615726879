import { Grid, Typography, useMediaQuery, useTheme, Divider, Button, IconButton } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Chart from 'react-apexcharts';
import COLOURS from 'utils/colors';
import useConfig from 'hooks/useConfig';
import { useEffect, useRef, useState } from 'react';
import useAuth from 'hooks/useAuth';
import axiosServices from 'utils/axios';
import { useParams } from 'react-router-dom';
import CircularLoader from 'ui-component/CircularLoader';
import PageHeader from 'views/ui-elements/PageHeader';
import { Download } from '@mui/icons-material';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportPage = () => {
    const { subjectId } = useParams();
    const { user } = useAuth();
    const graph1Ref = useRef();
    const { borderRadius } = useConfig();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [subjectName, setSubjectName] = useState();
    const [academicYear, setAcademicYear] = useState();
    const [overallPercentage, setOverallPercentage] = useState(0);
    const [overallLoaded, setOverallLoaded] = useState(false);
    const [brainSkillData, setBrainSkillData] = useState();
    const [brainSkillLoaded, setBrainSkillLoaded] = useState(false);
    const [questionTypeData, setQuestionTypeData] = useState();
    const [questionTypeLoaded, setQuestionTypeLoaded] = useState(false);
    const [difficultyLevelData, setDifficultyLevelData] = useState();
    const [difficultyLevelLoaded, setDifficultyLevelLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [questionData, setQuestionData] = useState();
    const [observationData, setObservationData] = useState();
    const [observationLoaded, setObservationLoaded] = useState(false);

    useEffect(() => {
        getSubjectName();
        getAcademicYear();
        getQuestionDetails();
        getOverallProgress();
        getSkillCard();
        getQuestionType();
        getDifficultyLevel();
    }, []);

    useEffect(() => {
        brainSkillData && brainSkillData.length && getObservations();
    }, [brainSkillData]);

    useEffect(() => {
        if (overallLoaded && brainSkillLoaded && questionTypeLoaded && difficultyLevelLoaded && observationLoaded) {
            setIsLoading(false);
        }
    }, [overallLoaded, brainSkillLoaded, questionTypeLoaded, difficultyLevelLoaded, observationLoaded]);

    const getSubjectName = () => {
        const localStorageValue = JSON.parse(localStorage.getItem('preSelectedValuesInDashboardPage'));
        setSubjectName(localStorageValue.name);
    };
    const getAcademicYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // January is 0, December is 11
        // If month is July or later, academic year starts in current year
        // Else, it starts in the previous year
        if (currentMonth >= 6) {
            // July is month 6
            setAcademicYear(`${currentYear}-${(currentYear + 1).toString().slice(-2)}`);
        } else {
            setAcademicYear(`${currentYear - 1}-${currentYear.toString().slice(-2)}`);
        }
    };
    const getQuestionDetails = async () => {
        const input = {
            studentId: user.id,
            subjectId
        };
        const response = await axiosServices.post(`/questions/details`, input);
        if (response.data) {
            console.log(response.data);
            setQuestionData(response.data);
        }
    };
    const getOverallProgress = async () => {
        setIsLoading(true);
        try {
            const response = await axiosServices.post(`/students/${user.id}/scores`, { subjectId: subjectId });
            const { marksObtained, totalMarks } = response.data;
            if (marksObtained != null && totalMarks != null) {
                const percentage = ((marksObtained / totalMarks) * 100).toFixed(2);
                setOverallPercentage(percentage);
            } else {
                setOverallPercentage(0);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setOverallLoaded(true);
        }
    };
    const getSkillCard = async () => {
        let input = {
            categoryId: 1, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setBrainSkillData(skills);
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            setBrainSkillLoaded(true);
        }
    };
    const getQuestionType = async () => {
        let input = {
            categoryId: 3, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setQuestionTypeData(skills);
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            setQuestionTypeLoaded(true);
        }
    };
    const getDifficultyLevel = async () => {
        let input = {
            categoryId: 2, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setDifficultyLevelData(skills);
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            setDifficultyLevelLoaded(true);
        }
    };
    const getObservations = async () => {
        try {
            const response = await axiosServices.post(`/observations`, brainSkillData);
            let observations = response.data.result;
            setObservationData(observations);
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            setObservationLoaded(true);
        }
    };

    const fillColor = Math.abs(overallPercentage) >= 75 ? COLOURS.GREEN : Math.abs(overallPercentage) >= 50 ? COLOURS.YELLOW : COLOURS.RED;
    var overallPerformanceChartData = {
        height: '380',
        type: 'radialBar',
        series: [Math.abs(overallPercentage)],
        options: {
            chart: {
                id: 'overall-performance-chart',
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            fill: {
                colors: [fillColor]
            },
            stroke: {
                show: true,
                lineCap: 'round'
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 10,
                            show: true,
                            fontSize: '25px',
                            fontWeight: 'bold',
                            color: fillColor
                        }
                    }
                }
            }
        }
    };
    var skill1ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: brainSkillData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: matchDownSm ? '60%' : '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };
    var skill2ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: difficultyLevelData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: matchDownSm ? '60%' : '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };
    var skill3ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: questionTypeData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: matchDownSm ? '60%' : '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: matchDownSm ? '12px' : '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };

    const formatResponse = (response) => {
        let returnValue = [];
        response.data?.forEach((element) => {
            returnValue.push({
                x: element.Tag.name,
                y: ((element.marksObtained / element.totalMarks) * 100).toFixed(1)
            });
        });
        return returnValue;
    };

    function parseHTML(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const listItems = tempDiv.querySelectorAll('ol li');
        return Array.from(listItems).map((li) => li.textContent);
    }

    const downloadPDF = async () => {
        // Note: This code assumes you have pdfmake properly set up in your project // Set your progress percentage here
        var trackColor = '#ecf0f1'; // Track color

        const chartHeight = 105;
        const marginLeft = 50;
        const marginBottom = 15;
        const marginTop = 20;
        const tickValues = [0, 20, 40, 60, 80, 100];
        const cornerRadius = 6;
        const standardBarWidth = 30;
        const barGap = 50;
        const chartWidth = 450;
        const observationContent = parseHTML(observationData);

        const docDefinition = {
            pageSize: 'A4',
            // watermark: { text: 'FINDINBOX', color: 'gray', opacity: 0.1, bold: true, italics: false },
            content: [
                {
                    columns: [
                        { width: '*', text: '' },
                        {
                            width: 'auto',
                            table: {
                                body: [
                                    [{ text: user.Partner ? user?.Partner.name : 'FindInbox Skill Card', style: 'header' }],
                                    [
                                        {
                                            text: [
                                                `Skill Card for the Academic Year ${academicYear} | Powered by`,
                                                { text: ` © www.findinbox.com`, color: COLOURS.BRAND_PRIMARY }
                                            ],
                                            style: 'subheader'
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        },
                        { width: '*', text: '' }
                    ]
                },
                {
                    stack: [
                        {
                            canvas: [
                                {
                                    type: 'rect', // A rectangle will be drawn
                                    x: 0, // x-position
                                    y: 0, // y-position
                                    w: 515, // width of the rectangle (adjust based on your page layout)
                                    h: 30, // height of the rectangle (adjust as needed)
                                    r: 5, // radius for rounded corners
                                    color: '#1B365D' // dark blue background color
                                }
                            ],
                            margin: 2
                        },
                        {
                            style: 'tableExample',
                            table: {
                                widths: ['*', '*', '*'],
                                body: [
                                    [
                                        { text: `Name: ${user.name}`, style: 'tableHeader' },
                                        { text: `Subject: ${subjectName}`, style: 'tableHeader', alignment: 'center' },
                                        {
                                            text: `Studying: ${user?.Branch?.name}`,
                                            style: 'tableHeader',
                                            alignment: 'right'
                                        }
                                    ]
                                ]
                            },
                            layout: {
                                hLineWidth: function () {
                                    return 0;
                                },
                                vLineWidth: function () {
                                    return 0;
                                },
                                hLineColor: function () {
                                    return 0;
                                }
                            },
                            margin: [5, -25, 4, 10]
                        }
                    ]
                },
                {
                    columns: [
                        {
                            width: '*',
                            text: [
                                { text: `Skill Questions Attempted:`, bold: true, fontSize: 11 },
                                { text: ` ${questionData?.answered}/${questionData?.total}`, fontSize: 11 }
                            ],
                            margin: [9, 0, 0, 0]
                        },
                        {
                            width: '*',
                            text: [
                                { text: `USN/Reg No:`, bold: true, fontSize: 11 },
                                { text: ` ${user.registerNumber}`, fontSize: 11 }
                            ],
                            alignment: 'right',
                            margin: [0, 0, 7, 0]
                        }
                    ]
                },
                {
                    text: 'Overall Progress',
                    fontSize: 11,
                    bold: true,
                    margin: [0, 15, 0, 10],
                    alignment: 'center',
                    decoration: 'underline'
                },
                {
                    svg: `
                          <svg width="100" height="100" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" stroke="${trackColor}" stroke-width="15" fill="none" />
                            <circle cx="50" cy="50" r="40" stroke="${fillColor}" stroke-width="15" fill="none" stroke-dasharray="251.2" stroke-dashoffset="${(100 - overallPercentage) * 2.512}" transform="rotate(-90 50 50)" stroke-linecap="round" />
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="12" font-weight="bold" fill="${fillColor}">${overallPercentage}%</text>
                          </svg>
                        `,
                    alignment: 'center',
                    width: 100,
                    height: 100
                },
                {
                    text: 'Skill Card - Brain Skills',
                    fontSize: 11,
                    bold: true,
                    margin: [0, 20, 0, 0],
                    alignment: 'center',
                    decoration: 'underline'
                },
                {
                    svg: `
                        <svg width="${chartWidth + marginLeft}" height="${chartHeight + marginBottom + marginTop}" viewBox="0 0 ${chartWidth + marginLeft + 10} ${chartHeight + marginBottom + marginTop}">
                            <!-- Y-axis and ticks -->
                            <line x1="${marginLeft}" y1="${marginTop}" x2="${marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />
                            ${tickValues
                                .map((tick) => {
                                    const y = marginTop + chartHeight - (tick / 100) * chartHeight;
                                    return `
                                            <line x1="${marginLeft - 5}" y1="${y}" x2="${chartWidth + marginLeft}" y2="${y}" stroke="#eeeeee" stroke-width="1" />
                                            <text x="${marginLeft - 10}" y="${y + 5}" text-anchor="end" font-size="10" color="#e2e2e2" font-weight="bold">${tick}%</text>
                                            `;
                                })
                                .join('')}

                            <!-- X-axis -->
                            <line x1="${marginLeft}" y1="${chartHeight + marginTop}" x2="${chartWidth + marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />

                            <!-- Bars and labels -->
                            ${brainSkillData
                                .map((item, index) => {
                                    const barHeight = (item.y / 100) * chartHeight;
                                    const x = marginLeft + 10 + index * (standardBarWidth + barGap);
                                    return `
                                <path
                                    d="
                                    M ${x + (barHeight > 0 ? cornerRadius : 0)} ${chartHeight + marginTop - barHeight}
                                    h ${barHeight > 0 ? standardBarWidth - 2 * cornerRadius : standardBarWidth}
                                    ${
                                        barHeight > 0
                                            ? `a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${cornerRadius}
                                        v ${barHeight - cornerRadius}
                                        h ${-standardBarWidth}
                                        v ${-barHeight + cornerRadius}
                                        a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${-cornerRadius}`
                                            : `v ${barHeight} h ${-standardBarWidth} v ${-barHeight}`
                                    }
                                    "
                                    fill="${skill1ChartData.options.colors[index]}"
                                />
                                <text
                                    x="${x + standardBarWidth / 2}"
                                    y="${chartHeight + marginTop - barHeight - 5}"
                                    text-anchor="middle"
                                    font-size="9"
                                    font-weight="bold"
                                    fill="#2e2e2e"
                                >${item.y}%</text>
                                <text
                                    x="${x + standardBarWidth / 2}"
                                    y="${chartHeight + marginTop + 15}"
                                    text-anchor="middle"
                                    font-size="9"
                                    font-weight="bold"
                                    fill="#2e2e2e"
                                    transform="rotate(0, ${x + standardBarWidth / 2}, ${chartHeight + marginTop})"
                                >${item.x}</text>
                                `;
                                })
                                .join('')}
                        </svg>
                    `
                },
                {
                    text: 'Performance - Question Type',
                    fontSize: 11,
                    bold: true,
                    margin: [0, 20, 0, 0],
                    alignment: 'center',
                    decoration: 'underline'
                },
                {
                    svg: `
                        <svg width="${chartWidth + marginLeft}" height="${chartHeight + marginBottom + marginTop}" viewBox="0 0 ${chartWidth + marginLeft + 10} ${chartHeight + marginBottom + marginTop}">
                            <!-- Y-axis and ticks -->
                            <line x1="${marginLeft}" y1="${marginTop}" x2="${marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />
                            ${tickValues
                                .map((tick) => {
                                    const y = marginTop + chartHeight - (tick / 100) * chartHeight;
                                    return `
                                            <line x1="${marginLeft - 5}" y1="${y}" x2="${chartWidth + marginLeft}" y2="${y}" stroke="#eeeeee" stroke-width="1" />
                                            <text x="${marginLeft - 10}" y="${y + 5}" text-anchor="end" font-size="10" color="#e2e2e2" font-weight="bold">${tick}%</text>
                                            `;
                                })
                                .join('')}

                            <!-- X-axis -->
                            <line x1="${marginLeft}" y1="${chartHeight + marginTop}" x2="${chartWidth + marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />

                            <!-- Bars and labels -->
                            ${questionTypeData
                                .map((item, index) => {
                                    const barHeight = (item.y / 100) * chartHeight;
                                    const x = marginLeft + 10 + index * (standardBarWidth + barGap);
                                    return `
                                <path
                                    d="
                                    M ${x + (barHeight > 0 ? cornerRadius : 0)} ${chartHeight + marginTop - barHeight}
                                    h ${barHeight > 0 ? standardBarWidth - 2 * cornerRadius : standardBarWidth}
                                    ${
                                        barHeight > 0
                                            ? `a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${cornerRadius}
                                        v ${barHeight - cornerRadius}
                                        h ${-standardBarWidth}
                                        v ${-barHeight + cornerRadius}
                                        a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${-cornerRadius}`
                                            : `v ${barHeight} h ${-standardBarWidth} v ${-barHeight}`
                                    }
                                    "
                                    fill="${skill2ChartData.options.colors[index]}"
                                />
                                <text
                                    x="${x + standardBarWidth / 2}"
                                    y="${chartHeight + marginTop - barHeight - 5}"
                                    text-anchor="middle"
                                    font-size="9"
                                    font-weight="bold"
                                    fill="#2e2e2e"
                                >${item.y}%</text>
                                <text
                                    x="${x + standardBarWidth / 2}"
                                    y="${chartHeight + marginTop + 15}"
                                    text-anchor="middle"
                                    font-size="9"
                                    font-weight="bold"
                                    fill="#2e2e2e"
                                    transform="rotate(0, ${x + standardBarWidth / 2}, ${chartHeight + marginTop})"
                                >${item.x}</text>
                                `;
                                })
                                .join('')}
                        </svg>
                    `
                },
                {
                    columns: [
                        {
                            stack: [
                                {
                                    text: 'Performance - Difficulty Level',
                                    fontSize: 11,
                                    bold: true,
                                    margin: [0, 20, 0, 0],
                                    alignment: 'center',
                                    decoration: 'underline'
                                },
                                {
                                    svg: `
                                            <svg width="${chartWidth / 2 + marginLeft}" height="${chartHeight + marginBottom + marginTop}" viewBox="0 0 ${chartWidth / 2 + marginLeft + 10} ${chartHeight + marginBottom + marginTop}">
                                                <!-- Y-axis and ticks -->
                                                <line x1="${marginLeft}" y1="${marginTop}" x2="${marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />
                                                ${tickValues
                                                    .map((tick) => {
                                                        const y = marginTop + chartHeight - (tick / 100) * chartHeight;
                                                        return `
                                                                <line x1="${marginLeft - 5}" y1="${y}" x2="${chartWidth / 2 + marginLeft}" y2="${y}" stroke="#eeeeee" stroke-width="1" />
                                                                <text x="${marginLeft - 10}" y="${y + 5}" text-anchor="end" font-size="10" color="#e2e2e2" font-weight="bold">${tick}%</text>
                                                                `;
                                                    })
                                                    .join('')}
                
                                                <!-- X-axis -->
                                                <line x1="${marginLeft}" y1="${chartHeight + marginTop}" x2="${chartWidth / 2 + marginLeft}" y2="${chartHeight + marginTop}" stroke="#e2e2e2" stroke-width="1" />
                
                                                <!-- Bars and labels -->
                                                ${difficultyLevelData
                                                    .map((item, index) => {
                                                        const barHeight = (item.y / 100) * chartHeight;
                                                        const x = marginLeft + 10 + index * (standardBarWidth + barGap);
                                                        return `
                                                    <path
                                                        d="
                                                        M ${x + (barHeight > 0 ? cornerRadius : 0)} ${chartHeight + marginTop - barHeight}
                                                        h ${barHeight > 0 ? standardBarWidth - 2 * cornerRadius : standardBarWidth}
                                                        ${
                                                            barHeight > 0
                                                                ? `a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${cornerRadius}
                                                            v ${barHeight - cornerRadius}
                                                            h ${-standardBarWidth}
                                                            v ${-barHeight + cornerRadius}
                                                            a ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} ${-cornerRadius}`
                                                                : `v ${barHeight} h ${-standardBarWidth} v ${-barHeight}`
                                                        }
                                                        "
                                                        fill="${skill3ChartData.options.colors[index]}"
                                                    />
                                                    <text
                                                        x="${x + standardBarWidth / 2}"
                                                        y="${chartHeight + marginTop - barHeight - 5}"
                                                        text-anchor="middle"
                                                        font-size="9"
                                                        font-weight="bold"
                                                        fill="#2e2e2e"
                                                    >${item.y}%</text>
                                                    <text
                                                        x="${x + standardBarWidth / 2}"
                                                        y="${chartHeight + marginTop + 15}"
                                                        text-anchor="middle"
                                                        font-size="9"
                                                        font-weight="bold"
                                                        fill="#2e2e2e"
                                                        transform="rotate(0, ${x + standardBarWidth / 2}, ${chartHeight + marginTop})"
                                                    >${item.x}</text>
                                                    `;
                                                    })
                                                    .join('')}
                                            </svg>
                                        `
                                }
                            ]
                        },
                        {
                            width: '45%',
                            stack: [
                                {
                                    text: 'Observations',
                                    fontSize: 11,
                                    bold: true,
                                    margin: [0, 20, 0, 0],
                                    alignment: 'center',
                                    decoration: 'underline'
                                },
                                {
                                    ul: observationContent.map((item) => ({ text: item, fontSize: 10, type: 'disc', lineHeight: 1.125 })),
                                    margin: [0, 10, 0, 0]
                                }
                            ]
                        }
                    ]
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    color: '#1B365D',
                    alignment: 'center'
                },
                subheader: {
                    fontSize: 12,
                    color: '#4A4A4A',
                    alignment: 'center',
                    bold: true
                },
                tableHeader: {
                    bold: true,
                    fontSize: 11,
                    color: 'white'
                },
                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                sectionHeader: {
                    fontSize: 14,
                    bold: true,
                    color: '#1B365D',
                    margin: [0, 15, 0, 5]
                },
                progressText: {
                    fontSize: 24,
                    bold: true,
                    color: '#FF6B6B',
                    alignment: 'center'
                },
                observations: {
                    fontSize: 10,
                    color: '#4A4A4A'
                }
            },
            defaultStyle: {
                columnGap: 20,
                font: 'Roboto'
            }
        };
        const cardName = `Skill Card_${subjectName}_${user.name}`;
        pdfMake.createPdf(docDefinition).download(cardName);
    };

    return (
        <>
            <PageHeader
                title={subjectName}
                hasBackButton={true}
                actionComponent={
                    <>
                        {!isLoading && (
                            <>
                                {matchDownSm ? (
                                    <IconButton
                                        sx={{
                                            background: theme.palette.primary.main,
                                            color: '#fff',
                                            ':hover': { background: theme.palette.primary.main, color: '#fff' }
                                        }}
                                        color="primary"
                                        size="small"
                                        onClick={downloadPDF}
                                    >
                                        <Download />
                                    </IconButton>
                                ) : (
                                    <Button variant="contained" startIcon={<Download />} onClick={downloadPDF}>
                                        Download Now
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                }
            />
            {isLoading ? (
                <>
                    <CircularLoader />
                </>
            ) : (
                <>
                    <Grid container sx={{ background: '#fff', borderRadius: '8px' }} p={{ xs: 2, sm: 3, md: 4 }} ref={graph1Ref}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                                <Grid item xs={9}>
                                    <Typography variant={matchDownSm ? 'h3' : 'h1'} m={2}>
                                        {user.Partner ? user?.Partner.name : 'FindInbox Skill Card'}
                                    </Typography>
                                    <Typography variant={matchDownSm ? 'h5' : 'h2'} m={2}>
                                        Skill Card for the Academic Year {academicYear} | Powered by{' '}
                                        <span style={{ color: '#4a79c9' }}>&copy; www.findinbox.com</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                mt={2}
                                container
                                flexDirection={matchDownMd ? 'column' : 'row'}
                                justifyContent={'space-around'}
                                alignItems={matchDownMd ? 'flex-start' : 'center'}
                                sx={{ background: '#0e2245' }}
                                borderRadius={'5px'}
                                flexGrow={1}
                            >
                                <Grid item p={2}>
                                    <Typography
                                        variant={matchDownSm ? 'body1' : 'h2'}
                                        color={'white'}
                                        mb={matchDownMd ? 1 : 0}
                                        textAlign={'start'}
                                    >
                                        Name: {user.name}
                                    </Typography>
                                </Grid>
                                <Grid item p={2}>
                                    <Typography
                                        variant={matchDownSm ? 'body1' : 'h2'}
                                        color={'white'}
                                        mb={matchDownMd ? 1 : 0}
                                        textAlign={'start'}
                                    >
                                        Subject: {subjectName}
                                    </Typography>
                                </Grid>
                                <Grid item p={2}>
                                    <Typography variant={matchDownSm ? 'body1' : 'h2'} color={'white'} textAlign={'end'}>
                                        Studying: {user?.Branch?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                flexDirection={matchDownMd ? 'column' : 'row'}
                                justifyContent={'space-between'}
                                alignItems={matchDownMd ? 'flex-start' : 'center'}
                                borderRadius={'5px'}
                                mt={2}
                                px={2}
                            >
                                <Grid item>
                                    <Typography variant={matchDownSm ? 'body1' : 'h3'} mb={matchDownMd ? 1 : 0} color={'#0e2245'}>
                                        Skill Questions Attempted: {questionData?.answered}/{questionData?.total}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={matchDownSm ? 'body1' : 'h3'} color={'#0e2245'}>
                                        USN/Reg No: {user.registerNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} md={6} lg={4} textAlign={'center'}>
                                    <Typography variant={matchDownSm ? 'h4' : 'h2'}>Overall Progress</Typography>
                                    <Chart {...overallPerformanceChartData} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} textAlign={'center'}>
                                    <Typography variant={matchDownSm ? 'h4' : 'h2'}>Skill Card - Brain Skills</Typography>
                                    <Chart {...skill1ChartData} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ borderColor: 'dark' }} />
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} textAlign={'center'}>
                                    <Typography variant={matchDownSm ? 'h4' : 'h2'}>Performance - Question Type</Typography>
                                    <Chart {...skill3ChartData} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ borderColor: 'dark' }} />
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} sm={12} md={6} lg={6} textAlign={'center'}>
                                    <Typography variant={matchDownSm ? 'h4' : 'h2'}>Performance - Difficulty Level</Typography>
                                    <Chart {...skill2ChartData} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                                        Observations
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: observationData }}></div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} justifyContent={'center'} display={'flex'} alignItems={'center'}>
                        <Grid item xs={12} textAlign={'center'}>
                            {!isLoading && (
                                <Button
                                    variant="contained"
                                    size={matchDownSm ? 'medium' : 'large'}
                                    startIcon={<Download />}
                                    onClick={downloadPDF}
                                >
                                    Download Now
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ReportPage;
