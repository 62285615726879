const STRINGS = {
    EXIT_ASSESSMENT_TITLE: 'Are you sure you want to exit?',
    EXIT_ASSESSMENT_CONTENT:
        'If you exit this assessment, it will be marked as incomplete and will not be considered for calculating performances. The changes will be stored and you can resume the assessment from the assessments list page later. Are you sure you want to exit?',
    ASSESSMENT_INSTRUCTIONS_TITLE: 'Instructions',
    ASSESSMENT_INSTRUCTIONS_CONTENT:
        '<ol><li>This is a <b>ONE TIME</b> attempt assessment. Once completed, it cannot be reversed or attempted again.</li><li>A timer next to the question will display the remaining time available to answer the question.</li><li>Click on <b>‘Next’</b> to get new question.</li><li>The answer will be saved automatically upon clicking <b>‘Next’</b>.</li><li>Unanswered questions cannot be answered later.</li><li>If you click <b>‘Exit Assessment’</b> before completing the quiz, you can attempt it later.</ol>'
};

export default STRINGS;
