import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import ReportDialog from 'views/ui-elements/ReportDialog';
const { Grid, Typography, Button } = require('@mui/material');
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import useAuth from 'hooks/useAuth';
import MuiTooltip from '@mui/material/Tooltip';
import { YouTube } from '@mui/icons-material';
import noVideosImage from 'assets/images/app-images/no-video.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SimpleDialog from 'views/ui-elements/SimpleDialog';
import VideoContentPage from './video-modal-content';

const MediaCard = ({ selectedElement, chapterMedia }) => {
    const [media, setMedia] = useState();
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState();

    useEffect(() => {
        // selectedElement && selectedElement.Media && setMedia(selectedElement.Media.filter((element) => element.type == 'VIDEO'));
        chapterMedia && chapterMedia.length && setMedia(chapterMedia.filter((element) => element.type == 'VIDEO'));
    }, [selectedElement]);

    // const handleReportButtonClick = () => {
    //     setIsReportModalOpen(true);
    // };

    const openVideoModal = (video) => {
        setVideoModalOpen(true);
        setSelectedVideo(video);
    };

    const handleVideoModalClose = () => {
        setVideoModalOpen(false);
    };

    const handleReportSubmit = async (reason) => {
        try {
            let input = {
                studentId: user.id,
                reportedItemId: selectedElement?.id, // Replace with the actual reported item ID
                reportedItemType: 'VIDEO', // Replace with the actual reported item type
                reason: reason
            };
            const response = await axiosServices.post('/report', input);
            if (response.status == 200) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Video was reported successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'There was a problem while reporting the video. Please try later!',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    return (
        <div>
            {selectedElement && media?.length ? (
                <Grid container alignItems="center" spacing={2} overflow={'auto'}>
                    <Grid item xs={12}>
                        <PerfectScrollbar style={{ height: 397 }}>
                            {media.map((video, index) => (
                                <Grid item xs={12} key={index} style={{ borderBottom: '1px solid #eee' }} py={1}>
                                    <Grid container item direction="row" alignItems="center">
                                        <Grid item xs={12}>
                                            <Grid container direction="row" display={'flex'} alignItems="center">
                                                <Grid item xs={1} display={'flex'} alignItems="center">
                                                    <YouTube color="error" />
                                                </Grid>
                                                <Grid item xs={8} display={'flex'} alignItems="center">
                                                    <Typography pl={0.5} variant="h5" color="inherit">
                                                        {video.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                                                    <MuiTooltip title="Watch Video">
                                                        <Button variant="outlined" size="small" onClick={() => openVideoModal(video)}>
                                                            Watch
                                                        </Button>
                                                    </MuiTooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </PerfectScrollbar>
                    </Grid>
                </Grid>
            ) : (
                <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
                    <Grid item>
                        <img src={noVideosImage} alt="Not found" width={'150px'} />
                    </Grid>
                </Grid>
            )}
            <ReportDialog open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} onSubmit={handleReportSubmit} />
            <SimpleDialog
                onClose={handleVideoModalClose}
                open={videoModalOpen}
                content={<VideoContentPage video={selectedVideo} selectedElement={selectedElement} />}
                title={selectedVideo?.name}
            />
        </div>
    );
};

MediaCard.propTypes = {
    selectedChapter: PropTypes.object,
    chapterMedia: PropTypes.array
};

export default MediaCard;
