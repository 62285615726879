// src/SelectableTable.js
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Toolbar,
    Typography,
    TextField,
    Button,
    Grid,
    CardMedia,
    Stack,
    useMediaQuery,
    useTheme,
    InputBase,
    Divider,
    Chip
} from '@mui/material';
import axiosServices from 'utils/axios';
import CircularLoader from 'ui-component/CircularLoader';
import useAuth from 'hooks/useAuth';
import logo from 'assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import imageEmpty from 'assets/images/app-images/empty.svg';
import currency from 'currency.js';
import SubCard from 'ui-component/cards/SubCard';

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const SelectableTable = () => {
    const [selected, setSelected] = useState([]);
    const [order] = useState('asc');
    const [orderBy] = useState('course');
    const [filter, setFilter] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const [discountTiers, setDiscountTiers] = useState();
    const [additionalDiscountPercent, setAdditionalDiscountPercent] = useState(0);
    const [additionalDiscountAmount, setAdditionalDiscountAmount] = useState(0);
    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [promoDiscountPercent, setPromoDiscountPercent] = useState(0);
    const [promoDiscountAmount, setPromoDiscountAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [enteredPromoCode, setEnteredPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [appliedPromoCode, setAppliedPromoCode] = useState('');

    const getAllSubjects = async () => {
        try {
            const response = await axiosServices.get(`/subjectsNotWithStudent/${user.id}`);
            if (response.data) {
                setSubjects(response.data);
            } else {
                setSubjects([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const loadRazorPayScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const getDiscountTiers = async () => {
        try {
            const response = await axiosServices.get(`/discount-tiers`);
            if (response.data) {
                setDiscountTiers(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const calculateTotalAmountInCart = () => {
        /* calculate subtotal amount */
        let subTotal = selected.reduce((total, subject) => total + subject.price, 0);
        setSubTotalAmount(subTotal);

        /* calculate additional discount */
        const totalSubjectsSelected = selected.length;
        let addDiscountAmount = 0;
        if (totalSubjectsSelected > 0) {
            let applicableDiscountPercentage = discountTiers
                .filter((tier) => selected.length >= tier.subjectCount)
                .reduce((max, tier) => (tier.discountPercentage > max ? tier.discountPercentage : max), 0);
            setAdditionalDiscountPercent(applicableDiscountPercentage);
            // calculate additional discount value
            if (applicableDiscountPercentage > 0) {
                addDiscountAmount = (subTotal * applicableDiscountPercentage) / 100;
                setAdditionalDiscountAmount(addDiscountAmount);
            } else {
                setAdditionalDiscountAmount(0);
            }
        } else {
            setAdditionalDiscountAmount(0);
            setAdditionalDiscountPercent(0);
        }

        /* calculate promo discount */
        calculatePromoCodeValues();
    };

    const calculateFinalAmount = () => {
        /* calculate final amount */
        setFinalAmount(subTotalAmount - additionalDiscountAmount - promoDiscountAmount);
    };

    useEffect(() => {
        calculatePromoCodeValues();
    }, [appliedPromoCode, subTotalAmount, additionalDiscountAmount]);

    useEffect(() => {
        getAllSubjects();
        getDiscountTiers();
        loadRazorPayScript('https://checkout.razorpay.com/v1/checkout.js');
    }, []);

    useEffect(() => {
        calculateTotalAmountInCart();
    }, [selected, appliedPromoCode]);

    useEffect(() => {
        calculateFinalAmount();
    }, [subTotalAmount, additionalDiscountAmount, promoDiscountAmount]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(subjects);
        } else {
            setSelected([]);
        }
    };

    const handleClick = (event, row) => {
        const selectedIndex = selected.findIndex((selectedRow) => selectedRow.id === row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const assignSubjectsToStudent = async () => {
        try {
            const response = await axiosServices.post(`/students/bulkAssignSubjects`, {
                studentIds: [user.id],
                subjectIds: selected.map((subject) => subject.id)
            });
            if (response.data) {
                console.log('subject assignment successful');
                navigate('/dashboard');
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const updateOrder = async (orderId) => {
        try {
            const response = await axiosServices.put(`/order`, { orderId });
            if (response.data) {
                console.log('order update successful');
                assignSubjectsToStudent();
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const launchRazorPay = (order) => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: order.totalAmount * 100,
            currency: 'INR',
            name: 'FINDINBOX PRIVATE LIMITED',
            description: 'FindInbox purchase',
            image: { logo },
            order_id: order.razorPayOrderId,
            handler: async function (response) {
                // once payment is successful, update the order status of the orderId in our tables
                console.log(response);
                updateOrder(order.id);
            },
            prefill: {
                name: user.name,
                email: user.email
            },
            theme: {
                color: '#61dafb'
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const createOrder = async () => {
        const order = {
            subjects: selected,
            orderDate: new Date().toISOString(),
            subTotal: subTotalAmount,
            additionalDiscountAmount: additionalDiscountAmount,
            promoDiscountAmount: promoDiscountAmount,
            totalDiscount: additionalDiscountAmount + promoDiscountAmount,
            promoCodeUsed: appliedPromoCode.code,
            studentId: user.id,
            totalAmount: finalAmount,
            status: 'pending',
            remarks: ''
        };
        try {
            const response = await axiosServices.post(`/order`, order);
            if (response.data) {
                launchRazorPay(response.data);
            } else {
                console.log('error in order api');
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
            setIsLoading(false);
        }
    };

    const handleBuySelected = () => {
        // create an order with selected items.
        setIsLoading(true);
        // create a new order
        createOrder();
    };

    const applyPromoCode = async () => {
        // call the promocode api
        try {
            const response = await axiosServices.get(`/promo-code?code=${enteredPromoCode.toUpperCase()}`);
            if (response.data) {
                setPromoCodeError('');
                setAppliedPromoCode(response.data);
            } else {
                setPromoCodeError('Promo Code is invalid');
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const calculatePromoCodeValues = () => {
        if (appliedPromoCode) {
            setPromoDiscountPercent(appliedPromoCode.discountPercentage);
            let currentSubTotal = subTotalAmount - additionalDiscountAmount;
            let promoCodeDiscountAmount = (currentSubTotal * appliedPromoCode.discountPercentage) / 100;
            setPromoDiscountAmount(promoCodeDiscountAmount);
        } else {
            setPromoDiscountPercent(0);
            setPromoDiscountAmount(0);
        }
    };

    const removePromoCode = async () => {
        setAppliedPromoCode('');
    };

    const handlePromoCodeChange = (event) => {
        setEnteredPromoCode(event.target.value);
    };

    const isSelected = (row) => selected.some((selectedRow) => selectedRow.id === row.id);

    const filteredSubjects = subjects.filter((row) => {
        return (
            row.name.toLowerCase().includes(filter.toLowerCase()) ||
            row.courseId.toLowerCase().includes(filter.toLowerCase()) ||
            row.branchId.toLowerCase().includes(filter.toLowerCase()) ||
            row.semesterId.toString().includes(filter) ||
            row.description.toLowerCase().includes(filter.toLowerCase()) ||
            row.price.toLowerCase().includes(filter.toLowerCase())
        );
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading ? (
                <Grid item my={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularLoader />
                </Grid>
            ) : (
                <>
                    <Toolbar sx={{ pl: '20px !important' }}>
                        <Typography variant="h4" id="tableTitle" component="div">
                            Select subjects to get skill card
                        </Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder="Search..."
                            value={filter}
                            onChange={handleFilterChange}
                            sx={{ marginLeft: 'auto' }}
                        />
                    </Toolbar>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={12} xs={12} md={6} lg={6} sx={{ borderRight: '1px solid #eee' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < subjects.length}
                                                    checked={subjects.length > 0 && selected.length === subjects.length}
                                                    onChange={handleSelectAllClick}
                                                />
                                            </TableCell>
                                            <TableCell>Subject Name</TableCell>
                                            {/* <TableCell sortDirection={orderBy === 'course' ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'course'}
                                                    direction={orderBy === 'course' ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, 'course')}
                                                >
                                                    Course
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={orderBy === 'branch' ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'branch'}
                                                    direction={orderBy === 'branch' ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, 'branch')}
                                                >
                                                    Branch
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={orderBy === 'semester' ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'semester'}
                                                    direction={orderBy === 'semester' ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, 'semester')}
                                                >
                                                    Semester
                                                </TableSortLabel>
                                            </TableCell> */}
                                            <TableCell>Description</TableCell>
                                            <TableCell>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(filteredSubjects, getComparator(order, orderBy)).map((row, index) => {
                                            const isItemSelected = isSelected(row);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    {/* <TableCell>{row.courseId}</TableCell>
                                                    <TableCell>{row.branchId}</TableCell>
                                                    <TableCell>{row.semesterId}</TableCell> */}
                                                    <TableCell>{row.description}</TableCell>
                                                    <TableCell>
                                                        <Stack>
                                                            {row.price && (
                                                                <Typography variant="subtitle1">
                                                                    {currency(row.price, { symbol: '₹' }).format()}
                                                                </Typography>
                                                            )}
                                                            {row.normalDiscount && (
                                                                <Typography variant="caption" sx={{ textDecoration: 'line-through' }}>
                                                                    {currency(row.normalDiscount, { symbol: '₹' }).format()}
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6}>
                            {selected && selected.length > 0 ? (
                                <>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                                                <TableRow>
                                                    <TableCell>Subject</TableCell>
                                                    <TableCell align="right">Price</TableCell>
                                                    <TableCell align="center">Quantity</TableCell>
                                                    <TableCell align="right">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selected.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Grid container alignItems="center" spacing={2}>
                                                                    <Grid item>
                                                                        <Stack spacing={0}>
                                                                            <Typography variant="subtitle1">{row.name}</Typography>
                                                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                                                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                                                                    {row.description}
                                                                                </Typography>
                                                                            </Stack>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Stack>
                                                                    {row.price && (
                                                                        <Typography variant="subtitle1">
                                                                            {currency(row.price, { symbol: '₹' }).format()}
                                                                        </Typography>
                                                                    )}
                                                                    {row.normalDiscount && (
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{ textDecoration: 'line-through' }}
                                                                        >
                                                                            {currency(row.normalDiscount, { symbol: '₹' }).format()}
                                                                        </Typography>
                                                                    )}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="center">1</TableCell>
                                                            <TableCell align="right">
                                                                {row.price && (
                                                                    <Typography variant="subtitle1">
                                                                        {currency(row.price, { symbol: '₹' }).format()}
                                                                    </Typography>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* Summary */}
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} p={2}>
                                            <SubCard>
                                                <TableContainer>
                                                    <Table sx={{ minWidth: 'auto' }} size="small" aria-label="simple table">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography variant="subtitle1">
                                                                        Order Summary (All prices are inclusive of GST)
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sub Total</TableCell>
                                                                <TableCell align="right">
                                                                    <Typography variant="subtitle1">
                                                                        {currency(subTotalAmount, { symbol: '₹' }).format()}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Additional Discount ({`${additionalDiscountPercent}%`})
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Typography variant="subtitle1">
                                                                        {`- ${currency(additionalDiscountAmount, { symbol: '₹' }).format()}`}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Promo Discount ({`${promoDiscountPercent}% after Additional Discount`})
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Typography variant="subtitle1">
                                                                        {`- ${currency(promoDiscountAmount, { symbol: '₹' }).format()}`}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                                    <Typography variant="subtitle1">Total</Typography>
                                                                </TableCell>
                                                                <TableCell align="right" sx={{ borderBottom: 'none' }}>
                                                                    <Typography variant="subtitle1">
                                                                        {currency(finalAmount, { symbol: '₹' }).format()}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Grid container my={2} justifyContent={'end'}>
                                                    <Grid item xs={6}>
                                                        {promoCodeError != '' && <Typography color={'red'}>{promoCodeError}</Typography>}
                                                        {promoDiscountAmount > 0 ? (
                                                            <>
                                                                <Stack
                                                                    direction={'row'}
                                                                    justifyContent={'end'}
                                                                    alignItems={'center'}
                                                                    spacing={1}
                                                                >
                                                                    <Typography>Promo Applied:</Typography>
                                                                    <Chip
                                                                        label={enteredPromoCode}
                                                                        variant="filled"
                                                                        color="primary"
                                                                        onDelete={removePromoCode}
                                                                    />
                                                                </Stack>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Paper
                                                                    sx={{
                                                                        px: 0.25,
                                                                        py: 0.5,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        border: '1px solid',
                                                                        borderColor: 'divider'
                                                                    }}
                                                                >
                                                                    <InputBase
                                                                        sx={{ ml: 1, flex: 1, fontWeight: 500 }}
                                                                        fullWidth
                                                                        placeholder="Promo Code"
                                                                        value={enteredPromoCode}
                                                                        onChange={handlePromoCodeChange}
                                                                    />
                                                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="directions"
                                                                        onClick={() => {
                                                                            applyPromoCode();
                                                                        }}
                                                                    >
                                                                        Apply
                                                                    </Button>
                                                                </Paper>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item display={'flex'} justifyContent={'right'} xs={12}>
                                                        <Button variant="contained" color="primary" onClick={handleBuySelected}>
                                                            Checkout
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </SubCard>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid container justifyContent="center" spacing={gridSpacing} sx={{ my: 3 }}>
                                        <Grid item xs={12} sm={8} md={6}>
                                            <CardMedia component="img" image={imageEmpty} title="Slider5 image" />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Grid container direction="column" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant={downLG ? 'h3' : 'h1'} color="inherit">
                                                        Cart is Empty
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center">
                                                        Choose subjects to start learning.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Paper>
    );
};

export default SelectableTable;
