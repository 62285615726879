import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AccountSettingsPage from 'views/account-settings-page';
import AssessmentsListPage from 'views/assessments-list-page';
import SubjectsPage from 'views/subjects-page';
import PracticePage from 'views/practice-page';
import PracticeQuestionsPage from 'views/practice-page/questions';
import ReportPage from 'views/report-page';

// sample page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/subjects',
            element: <SubjectsPage />
        },
        {
            path: '/account-settings-page',
            element: <AccountSettingsPage />
        },
        {
            path: '/assessments-list-page',
            element: <AssessmentsListPage />
        },
        {
            path: '/practice/:subjectId',
            element: <PracticePage />
        },
        {
            path: '/practice/:subjectId/questions',
            element: <PracticeQuestionsPage />
        },
        {
            path: '/reportPage/:subjectId',
            element: <ReportPage />
        }
    ]
};

export default MainRoutes;
