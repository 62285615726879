import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosServices from 'utils/axios';
import AssessmentStatuses from './assessment_statuses';
import AlertDialog from 'views/ui-elements/AlertDialog';
import STRINGS from 'views/ui-elements/constants';
const PropTypes = require('prop-types');
const {
    Card,
    CardContent,
    CardHeader,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button
} = require('@mui/material');

const AssessmentCard = ({ assessment, chapter }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const [questions, setQuestions] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState();

    const gotoAssessmentsPage = (assessment) => {
        setSelectedAssessment(assessment);
        setDialogOpen(true);
    };

    const onAgree = () => {
        setDialogOpen(false);
        const status = selectedAssessment.Students[0]?.StudentAssessment?.isComplete
            ? AssessmentStatuses.COMPLETE.status
            : questions?.length
            ? AssessmentStatuses.INCOMPLETE.status
            : AssessmentStatuses.NOT_STARTED.status;
        navigate(`/assessment-page/${selectedAssessment?.id}`, {
            state: { chapter: chapter, status: status, assessmentName: selectedAssessment?.title }
        });
    };

    const onClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        const getAllQuestionsWithAnswersForAssessment = async () => {
            try {
                const response = await axiosServices.post(`/assessments/${assessment.id}/getAllQuestionsAndAnswers`, {
                    studentId: user.id
                });
                setQuestions(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                console.log('close loader. job done');
            }
        };
        getAllQuestionsWithAnswersForAssessment();
    }, [assessment, user]);

    return (
        <>
            <Card
                sx={{
                    border: '1px solid #eeeeee',
                    ':hover': {
                        boxShadow: theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    }
                }}
            >
                <CardHeader
                    title={assessment.title}
                    titleTypographyProps={{ variant: 'h5', align: 'center', fontSize: '16px !important' }}
                    style={{ borderBottom: '1px solid #eeeeee', padding: 10 }}
                />
                <CardContent style={{ padding: 10 }}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ border: 'none', p: 1 }} align="center">
                                        <Typography variant="h5">Questions</Typography>
                                        <Typography>{questions?.length}</Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none', p: 1 }} align="center">
                                        <Typography variant="h5">Correct</Typography>
                                        <Typography>
                                            {
                                                questions?.filter((question) => {
                                                    return question.marks > 0;
                                                }).length
                                            }
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none', p: 1 }} align="center">
                                        <Typography variant="h5">Wrong</Typography>
                                        <Typography>
                                            {
                                                questions?.filter((question) => {
                                                    return question.marks == 0;
                                                }).length
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button
                            variant="contained"
                            color={
                                assessment.Students[0]?.StudentAssessment?.isComplete
                                    ? AssessmentStatuses.COMPLETE.color
                                    : questions?.length
                                    ? AssessmentStatuses.INCOMPLETE.color
                                    : AssessmentStatuses.NOT_STARTED.color
                            }
                            size="small"
                            onClick={() => {
                                gotoAssessmentsPage(assessment);
                            }}
                        >
                            {assessment.Students[0]?.StudentAssessment?.isComplete
                                ? AssessmentStatuses.COMPLETE.buttonText
                                : questions?.length
                                ? AssessmentStatuses.INCOMPLETE.buttonText
                                : AssessmentStatuses.NOT_STARTED.buttonText}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <AlertDialog
                isOpen={dialogOpen}
                onAgree={onAgree}
                onClose={onClose}
                title={STRINGS.ASSESSMENT_INSTRUCTIONS_TITLE}
                content={STRINGS.ASSESSMENT_INSTRUCTIONS_CONTENT}
            ></AlertDialog>
        </>
    );
};

AssessmentCard.propTypes = {
    assessment: PropTypes.object,
    chapter: PropTypes.object,
    assessmentName: PropTypes.string
};

export default AssessmentCard;
