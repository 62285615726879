// project imports
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from 'views/ui-elements/PageHeader/index.js';
import SelectableTable from './selectible-table';

// ==============================|| SUBJECTS PAGE ||============================== //

const SubjectsPage = () => {
    return (
        <>
            <PageHeader title="Subjects" hasBackButton={false} />
            <MainCard content={false}>
                <SelectableTable />
            </MainCard>
        </>
    );
};

export default SubjectsPage;
