import { Alert, Button, Grid, LinearProgress, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeader from 'views/ui-elements/PageHeader';
import ChapterPopover from 'views/ui-elements/ChapterPopover';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import TopicSkillPopover from 'views/ui-elements/TopicSkillPopover';
import { gridSpacing } from 'store/constant';
// import PracticeCard from './practice-card';
import Assessment from 'views/ui-elements/Assessment';
import startPracticeImage from 'assets/images/app-images/no-ai-solution.svg';
import pickAChapterImage from 'assets/images/app-images/pick-a-chapter.svg';
import pickATopicImage from 'assets/images/app-images/pick-a-topic.svg';
// import leftArrow from 'assets/images/app-images/left-arrow.png';
import './style.css';
import { linearProgressClasses } from '@mui/material/LinearProgress';
// import MainCard from 'ui-component/cards/MainCard';
import { Box, useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import AlertDialog from 'views/ui-elements/AlertDialog';
// import DocumentsCard from './documents-card';
// import MediaCard from './media-card';

// linear progress with label
function LinearProgressWithLabel({ value, selectedElement, metrics, ...others }) {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: 'primary.800' }}>
                            Progress - {selectedElement ? `${metrics?.total_answers || 0}/${metrics?.total_questions || 0} Attempted` : 0}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <LinearProgress
                    aria-label="progress of theme"
                    variant="determinate"
                    value={value}
                    {...others}
                    sx={{
                        height: 10,
                        borderRadius: 30,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                            bgcolor: 'background.paper'
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 5,
                            bgcolor: 'primary.dark'
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    metrics: PropTypes.object,
    selectedElement: PropTypes.object,
    value: PropTypes.number
};

// tab content customize
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number
};

const PracticePage = () => {
    const { state } = useLocation();
    const [selectedChapter, setSelectedChapter] = useState();
    const { user } = useAuth();
    const [topics, setTopics] = useState();
    const [categories, setCategories] = useState();
    const [reset, setReset] = useState(true);
    const [selectedElement, setSelectedElement] = useState();
    const navigate = useNavigate();
    const { chapters, subject } = state?.chapters && state?.subject ? state : { chapters: [], subject: [] };
    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState();
    const mode = 'result';
    // const [notesOrVideos, setNotesOrVideos] = useState(0);
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [chapterMedia, setChapterMedia] = useState([]);
    const [metrics, setMetrics] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const instructions = `<div>
            <ol>
                <li>
                    <strong>One-time attempt:</strong> This is a single attempt practice quiz. Once submitted, you cannot retake or change
                    your answers.
                </li>
                <li>
                    <strong>Time limit:</strong> Each question has a specific time limit. Answer within the allotted time.
                </li>
                <li>
                    <strong>Moving to the next question:</strong> Click &apos;Next&apos; to proceed to the next question and automatically
                    save your answer.
                </li>
                <li>
                    <strong>Resuming the quiz:</strong> If you go back without answering a question, you can answer it later by resuming the
                    quiz from where you left off.
                </li>
                <li>
                    <strong>Unanswered questions:</strong> If you click &apos;Next&apos; without answering, the question will be marked as
                    unanswered and cannot be answered later.
                </li>
                <li>
                    <strong>Reporting questions:</strong> If you believe a question or its options are incorrect, you can report the
                    question.
                </li>
                <li>
                    <strong>Final submission:</strong> Review your answers carefully before submitting the quiz, as no changes can be made
                    afterward.
                </li>
            </ol>
            <br />
            <div style="text-align: center;"><strong>Good luck with your quiz!</strong></div>
        </div>`;

    useEffect(() => {
        getTopicMetrics();
    }, [selectedElement]);

    const getTopicMetrics = async () => {
        try {
            let queryParam = selectedElement.chapterId ? `topicId=${selectedElement.id}` : `tagId=${selectedElement.id}`;
            /* if a user belongs to a partner, then show only verified questions
                if a user does not belong to a partner (patnerId is null), 
                then this user is a demo user and should show all questions including unverified ones */
            let url = `/chapters/${selectedChapter?.id}/students/${user.id}/metrics?${queryParam}`;
            if (user.partnerId) {
                url = url + `&verifiedOnly=true`;
            }
            const response = await axiosServices.get(url);
            /* end */
            let mets = response.data;
            setMetrics(mets);
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    useEffect(() => {
        if (!chapters.length || !subject) {
            navigate('/dashboard');
        }
    });

    const clearQuestions = () => {
        setQuestions([]);
        setAnswers([]);
    };

    const onChapterSelected = (newChapter) => {
        setSelectedChapter(newChapter);
        getChapterDetails(newChapter);
        selectedChapter && newChapter.id != selectedChapter.id && resetTopicsAndSkills();
        clearQuestions();
    };

    const onTopicOrSkillSelected = async (selectedElement) => {
        setSelectedElement(selectedElement);
        setReset(false);
        getQuestionsForPractice(selectedElement);
        if (!selectedElement.chapterId) {
            // this means that you have selected a skill
            // get skill performances
            let skills = await getTagPerformances(selectedElement.categoryId);
            let newElement;
            skills.forEach((skill) => {
                if (skill.Tag.id == selectedElement.id) {
                    newElement = JSON.parse(JSON.stringify(selectedElement));
                    newElement.performance = Math.round((skill.marksObtained / skill.totalMarks) * 100) || null;
                    setSelectedElement(newElement);
                }
            });
        }
    };

    const getTagPerformances = async (scId) => {
        let input = {
            categoryId: scId,
            subjectId: subject.id,
            chapterId: selectedChapter.id
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            return response.data;
        } catch (error) {
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const getQuestionsForPractice = async (element) => {
        const input = {
            chapterId: selectedChapter?.id,
            classification: 'PRACTICE' //this is question classification
        };
        if (element.chapterId) {
            input.topicId = element.id;
        } else {
            input.tagId = element.id;
        }
        /* if a user belongs to a partner, then show only verified questions
           if a user does not belong to a partner (patnerId is null), 
           then this user is a demo user and should show all questions including unverified ones */
        if (user.partnerId) {
            input.verifiedOnly = true;
        }
        /* end */
        try {
            const response = await axiosServices.post(`/questions/practice`, input);
            if (response.data && response.data.length) {
                setQuestions(response.data);
                getAnswersForQuestions(response.data);
            } else {
                clearQuestions();
            }
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const getAnswersForQuestions = async (ques) => {
        const input = {
            studentId: user.id,
            questionIds: ques.map((question) => {
                return question.id;
            })
        };
        try {
            const response = await axiosServices.post(`/answers/practice`, input);
            if (response.data && response.data.length) {
                setAnswers(response.data);
            }
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const getAllTopics = async (selectedChapter) => {
        try {
            const response = await axiosServices.get(`/topics?chapterId=${selectedChapter?.id}&studentId=${user.id}`);
            let orderedTopics = response.data.length
                ? response.data.sort((a, b) => {
                      return a.displayOrder - b.displayOrder;
                  })
                : [];
            setTopics(orderedTopics);
            // New change: This change is to get all videos and documents in the chapter.
            let temp = [];
            orderedTopics.forEach((topic) => {
                temp.push(topic.Media);
            });
            let cm = temp.flat().sort((a, b) => {
                return a.id - b.id;
            });
            setChapterMedia(cm);
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const getSkillCategories = async () => {
        try {
            const response = await axiosServices.get(`/categories/${subject?.id}`);
            let cats = [];
            if (response.data && response.data.Categories && response.data.Categories.length > 0) {
                cats = response.data.Categories.sort((firstItem, secondItem) => {
                    const category1 = firstItem.id;
                    const category2 = secondItem.id;
                    if (category1 < category2) {
                        return -1;
                    }
                    if (category1 > category2) {
                        return 1;
                    }
                    return 0;
                });
                setCategories(cats);
            } else {
                setCategories([]);
            }
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const getChapterDetails = (selectedChapter) => {
        getAllTopics(selectedChapter);
        getSkillCategories();
    };

    const resetTopicsAndSkills = () => {
        setReset(true);
        setSelectedElement('');
    };

    // const handleTabChange = (e, newValue) => {
    //     setNotesOrVideos(newValue);
    // };

    const goToAssessment = (mode) => {
        let topic = null,
            skill = null;
        if (selectedElement.chapterId) {
            topic = selectedElement;
        } else {
            skill = selectedElement;
        }
        /* persist the selected chapter, topic or skill */
        localStorage.setItem('preSelectedValuesInPracticePage', JSON.stringify({ chapter: selectedChapter, element: selectedElement }));
        /* navigate to practice questions */
        navigate(`/practice/${selectedChapter.subjectId}/questions`, {
            state: { chapter: selectedChapter, topic: topic, skill: skill, mode: mode }
        });
    };

    const openDialog = (mode) => {
        if (mode == 'practice') {
            setDialogOpen(true);
        } else {
            goToAssessment(mode);
        }
    };

    const onAgree = () => {
        setDialogOpen(false);
        goToAssessment('practice');
    };

    const onClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <PageHeader title={`Learn and Practice - ${subject?.name}`} hasBackButton={true} />
            <Grid container display={'flex'} alignItems="center" justifyContent="start" spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ChapterPopover chapters={chapters} onChapterSelected={onChapterSelected} />
                        </Grid>
                        {selectedChapter && (
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TopicSkillPopover
                                    topics={topics}
                                    categories={categories}
                                    onSelected={onTopicOrSkillSelected}
                                    reset={reset}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Grid container spacing={2} display={'flex'} justifyContent={'space-between'}>
                        {selectedChapter && selectedElement && (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <LinearProgressWithLabel
                                    metrics={metrics}
                                    selectedElement={selectedElement}
                                    value={
                                        selectedElement && metrics?.total_questions
                                            ? (metrics?.total_answers / metrics?.total_questions) * 100
                                            : 0
                                    }
                                />
                            </Grid>
                        )}
                        {selectedChapter && selectedElement && selectedElement?.performance && (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Tooltip title="The number indicates your performance in the selected topic or skill.">
                                    <div>
                                        {selectedElement?.performance < 50 && (
                                            <Alert severity="error" variant="filled" sx={{ paddingY: '4px' }}>
                                                {selectedElement?.performance}% - Improve
                                            </Alert>
                                        )}
                                        {selectedElement?.performance >= 50 && selectedElement.performance < 75 && (
                                            <Alert severity="warning" variant="filled" sx={{ paddingY: '4px' }}>
                                                {selectedElement?.performance}% - Can Improve
                                            </Alert>
                                        )}
                                        {selectedElement?.performance >= 75 && (
                                            <Alert severity="success" variant="filled" sx={{ paddingY: '4px' }}>
                                                {selectedElement?.performance}% - Good
                                            </Alert>
                                        )}
                                    </div>
                                </Tooltip>
                            </Grid>
                        )}
                        {selectedChapter && selectedElement && (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Button
                                    size={'large'}
                                    fullWidth
                                    onClick={() => {
                                        openDialog('practice');
                                    }}
                                    color="primary"
                                    variant="contained"
                                    disabled={!selectedElement || metrics == null || metrics?.total_answers == metrics?.total_questions}
                                >
                                    Start Practice
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid spacing={gridSpacing} container mt={-0.5}>
                {selectedChapter && selectedElement ? (
                    <>
                        <Grid item xs={12} sm={12} md={12} height={matchDownSm ? '100%' : 530}>
                            {questions && questions.length > 0 ? (
                                <Assessment
                                    questions={questions}
                                    answers={answers}
                                    mode={mode}
                                    selectedElement={selectedElement}
                                    chapterMedia={chapterMedia}
                                />
                            ) : (
                                <>
                                    {!matchDownSm && (
                                        <Grid container display={'flex'} justifyContent={'center'} alignItems={'start'} height={'100%'}>
                                            <Grid item display={'flex'} justifyContent={'center'}>
                                                <img src={startPracticeImage} alt="Not found" width={'45%'} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </>
                ) : (
                    <>
                        {!selectedChapter ? (
                            <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} mt={10}>
                                <Grid item display={'flex'} justifyContent={'center'}>
                                    <img src={pickAChapterImage} alt="Not found" width={'150px'} />
                                </Grid>
                            </Grid>
                        ) : !selectedElement ? (
                            <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} mt={10}>
                                <Grid item display={'flex'} justifyContent={'center'}>
                                    <img src={pickATopicImage} alt="Not found" width={'150px'} />
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </>
                )}
            </Grid>
            <AlertDialog onClose={onClose} onAgree={onAgree} isOpen={dialogOpen} content={instructions} title={'Instructions'} />
        </>
    );
};

export default PracticePage;
